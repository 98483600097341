import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from "./components/home";
import AssistenciasScreen from "./components/assistencias";
import SistemaRedirectScreen from "./components/sistema-redirect";
import ErrorScreen from "./components/error";
import VidasRedirectScreen from "./components/vidas-redirect";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<HomeScreen/>}/>
      <Route exact path="/assistencias" element={<AssistenciasScreen/>}/>
      <Route exact path="/sistema/*" element={<SistemaRedirectScreen/>}/>
      <Route exact path="/vidas/*" element={<VidasRedirectScreen/>}/>
      <Route exact path="*" element={<ErrorScreen/>}/>
    </Routes>
  </BrowserRouter>
)

export default App;